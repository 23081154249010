<template>
  <div sheet>
    <Visual2 tit="PRODUCT" txt="현대제철이 만든<br class='mo'> 프리미엄 강재<br> <b>안</b>전을 <b>심</b>은 철,<br class='mo'> <b>안심</b> <i class='kv-logo'></i>" bg="product-kv"></Visual2>
    <div class="tab-wrap">
      <div class="tab main-tab" :class="{open:mainOpen, ready}" @click="mainToggle">
        <ul>
          <li><RouterLink to="/product">All Product</RouterLink></li>
          <li class="active"><RouterLink to="/section">SECTION</RouterLink></li>
          <li><RouterLink to="/bar">REINFORCING BAR</RouterLink></li>
          <li><RouterLink to="/plate">PLATE</RouterLink></li>
          <li><RouterLink to="/pipe">STEEL PIPE</RouterLink></li>
          <li><RouterLink to="/hrcr">HR | CR</RouterLink></li>
        </ul>
      </div>
      <div class="tab sub-tab" :class="{open:subOpen, ready}" @click="subToggle">
        <ul>
          <li><RouterLink to="/section">H형강 확대규격</RouterLink></li>
          <li><RouterLink to="/shp">토목 구조용 H형강 말뚝</RouterLink></li>
          <li><RouterLink to="/shn460">고강도 형강</RouterLink></li>
          <li><RouterLink to="/chb">무늬 H형강</RouterLink></li>
          <li class="active"><RouterLink to="/sheet">열간 압연 강 널말뚝</RouterLink></li>
          <li><RouterLink to="/shn">건축 구조용 H형강</RouterLink></li>
          <li><RouterLink to="/offshore">해양구조용 형강</RouterLink></li>
          <li><RouterLink to="/gr">친환경 인증</RouterLink></li>
        </ul>
      </div>
    </div>
    <article>
      <Paragraph>
        <div class="top para">
          <h2>SECTION  |  형강</h2>
          <div class="inner">
            <p class="tit">열간 압연강 널말뚝 <s></s><br class="mo"> Sheet Piles</p>
            <p class="txt">
              한국산업규격(KS F 4604)에 의해 생산되는 열간 압연강 널말뚝은
              구조적 안전성과 차수성이 입증된 자재로<br class="web"> 흙막이 및 물막이 용도로
              특화된 제품입니다. 현대제철은 기존의 400 Series를 넘어서<br class="web">  500,
              600 Series의 광폭 열간 압연 널말뚝 개발을 통해  최적설계 구축
              환경을 선도하고 있습니다.
            </p>
            <div class="btns-wrap">
              <a class="btn cr" href="/images/SECTION.pdf" target="_blank">카탈로그 <i></i></a>
              <a class="btn qna" @click="contactModal('형강');">1:1 문의하기</a>
            </div>
          </div>
        </div>
        <img src="/images/mo/sheet.png" alt="" class="para mo">
        <div class="web content-wrap para" :class="currentTab">
          <div class="anchor">
            <a class="btn"  @click="currentTab = 'tab0'">Advantage</a>
            <a class="btn"  @click="currentTab = 'tab1'">Detail</a>
          </div>
          <div class="img-wrap" id="advantage">
            <img src="/images/pc/sheet1.png" alt="">
          </div>
          <div class="img-wrap " id="detail">
            <img src="/images/pc/sheet2.png" alt="">
          </div>
        </div>
      </Paragraph>
    </article>
  </div>
</template>

<script>
import Visual2 from "@/views/components/Visual2";
import Paragraph from "@/views/components/Paragraph.vue";
import ContactModal from "@/views/components/modal/ContactModal";
export default {
  name: "Sheet",
  components: {Visual2, Paragraph },

  data() {
    return {
      mainOpen:  false,
      subOpen:  false,
      ready:  true,
      currentTab: 'tab0',
    }
  },
  mounted() {
    setTimeout(() =>{ this.ready = false },500);
  },
  methods: {
    mainToggle() {
      this.mainOpen = !this.mainOpen
    },
    subToggle() {
      this.subOpen = !this.subOpen
    },
    contactModal(prod) {
      this.$modal(ContactModal, {category:prod});
    },
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[sheet] {
  .tab-wrap { .bgc(#fff); .rel;
    &:after { .cnt; .wh(1,100%); .bgc(#000); .abs; .lt(50%,0); }
    .tab { transform: translateY(0); transition: transform 0.5s, opacity 0.5s; .ease-out; .bgc(#fff); .w(50%); .ib; .vat; .-box;
      ul { .tl; .rel; .h(102); overflow: hidden; transition: height 0.2s; .ease-out; .rel;
        &:after { .cnt; .wh(31,19); .contain('/images/mo/ico-prd-tab.png'); .abs; .rt(40,45); }
        li { .fs(30); .lh(70); .bold; .ls(-0.025em); .pointer; .spoqa; .-b(#333); .-box; .rel; .lt(0,100); .wf; color:#0d1112; transition: opacity 0.3s; .pl(45);
          a { .f; .ib; }
          &.active { .lh(100); .abs; .lt; }
          &:hover { .o(1); }
        }
      }
      &.open {
        ul { .h(388);
          &:after { transform: rotate(180deg); }
        }
        &.sub-tab ul { .h(606); }
      }
      &.ready { transform: translateY(100px); opacity: 0; }
    }
  }
  article { .bgc(#fff); .rel; .z(1);  .pb(100); .-box;
    .top {
      h2 { .fs(40); .p(90,0,60,50); .-box; }
      .inner { .bgc(#f2f2f2); .p(75,50,100,50); .-box;
        .tit { .fs(57,76); .bold; .mb(25); .ls(-0.025em);
          s { .ib; .rel; .wh(50,76); .vam;
            &:after { .cnt; .wh(4,45); .bgc(#333); .abs; .lt(50%,10); transform: translateX(-50%); }
          }
        }
        .txt { .fs(26,45); .ls(-0.05em); .mb(50); .keep-all; }
        .btns-wrap { .mh-c; .flex; .space-between;
          .btn { .fs(27,90); .c(#fff); .tc; .ls(-0.025em); .wh(40vw,90); .ib; .vam; .-a(#0075c1,2); .-box; .br(45);
            i { .wh(30,27); .ib; .vam; .contain('/images/mo/ico-down.png'); .ml(30); }
            &.cr { .bgc(#0075c1); }
            &.qna { .c(#0075c1); }
          }
        }
      }
    }
    img { .wf; }
  }
}
@media screen and(min-width: 1024px) {
  [sheet] {
    .tab-wrap { .rel;
      &:after { .hide; }
      .tab {
        &.main-tab { .wf; }
        ul { .tc; .h(85); .-b; .flex; .space-between; .max-w(1400); .mh-c; .rel;
          &:after { .cnt; .wh(100%,1); .bgc(#d8d8d8); .lb(0,5); }
          li { .fs(24); .lh(85); .ib; .vam; .o(0.2); .rel; left:auto; top:auto; .-b; .bold; transition: opacity 0.3s; .p(0);
            &.active { .fs(24); .lh(85); .o(1); left:auto; top:auto; .rel; .c(#0079ae);
              &:after { .cnt; .wh(100%,5); .bgc(#0079ae); .abs; .lb; }
            }
          }
        }
        &.open ul { height: auto;
          &.sup-tabs { height: auto; }
        }
        &.sub-tab { .wh(240,50); .abs; .lb(50%,-150); .ml(460); .z(2); .bgc(#fff);
          ul { .h(50); .block; .tl; .-a(#0d1112); .-box; .rel; .pt(48);
            &:after { .wh(14,9); .contain('/images/pc/ico-down-arrow.png'); .bgc(#fff); .abs; .rt(22,20); pointer-events: none; }
            &:before { .cnt; .wh(100%,1); .abs; .lt(0,50); .bgc(#0d1112); .z(1); }
            li { .fs(18,50); .h(50); .c(#0d1112); .wf; .block; .pl(20); .-box; .o(1); .regular; .bgc(#fff);
              &.active { .c(#0d1112); .lt; .abs;
                &:after { .hide; }
              }
            }
          }
          &.open ul { .h(400); }
        }
      }
    }
    article {
      .top { .max-w(1400); .mh-c;
        h2 { .p(100,0,45,0); }
        .inner { .p(80,0,100,0); .rel;
          &:after { .cnt; .wh(100vw,100%); .bgc(#f2f2f2); .abs; .lt(50%,0); transform: translateX(-50vw); .z(-1); }
          .tit { .mb(35); }
          .txt { .fs(20,30); .mb(60); }
          .btns-wrap { .tl; .block;
            .btn { .fs(18,60); .wh(247,60); .br(31); .mr(15);
              i { .wh(20,18); .contain('/images/pc/ico-down.png'); .ml(25); }
            }
          }
        }
      }
      .content-wrap { .w(1400); .mh-c; .tc; .mt(100); .block;
        .anchor { .wh(1400,62); .tl; .block; .-b(#0079ae,2px); .-box;
          .btn { .fs(18,60); .h(62); .c(#999); .medium; .w(33.333%); .-box; .ib; .vam; .tc; .bgc(#fafafa); .-t(#d6d6d6); .-r(#d6d6d6); .-b(#0079ae,2px); text-transform: uppercase;
            &:first-child { .-l(#d6d6d6); }
          }
        }
        img { .max-w(1400); .mh-c;
          &.web { .block; }
        }
        &.tab0 .anchor .btn:nth-child(1),
        &.tab1 .anchor .btn:nth-child(2),
        &.tab2 .anchor .btn:nth-child(3) { .c(#0075c1); .-t(#0075c1,2px); .-l(#0075c1,2px); .-r(#0075c1,2px); .-b(#fff); .bgc(#fff); }
        &.tab1 #advantage,
        &.tab2 #advantage, &.tab2 #detail { .hide; }
      }
    }
  }
}
</style>
